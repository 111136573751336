import { InjectedConnector } from '@web3-react/injected-connector';

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97],
});

export const SUPPORTED_WALLETS = [
  {
    name: 'METAMASK',
    data: {
      connector: injected,
      name: 'MetaMask',
      iconName: '/images/metamask-fox.svg',
      description: 'Easy-to-use browser extension.',
      href: null,
      color: '#E8831D',
    },
  },
];
