import React from "react";
import clsx from "clsx";

import { Link } from "react-router-dom";
import { Link as RouterLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Button,
  Hidden,
  Container,
  Grid,
  DialogTitle,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "./RightDrawer";
import { PieChart as PieChartIcon, Info as InfoIcon } from "react-feather";
import Logo from "src/component/Logo";
import { useWeb3React } from "@web3-react/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdb52d",
  },
  toolbar: {
    margin: "10px 0 10px",
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: "500",
    fontFamily: "'Montserrat', sans-serif",
    color: "#102A49",
  },
  navButton: {
    marginLeft: theme.spacing(8),
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  ButtonNav: {
    fontSize: 24,
    lineHeight: 9,
    fontWeight: 700,
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/dashboard",
      },
      {
        title: "Staking",
        icon: PieChartIcon,
        href: "/stake",
      },
     
    ],
  },
];

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const { chainId, account } = useWeb3React();

  return (
    <AppBar
      className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
      elevation={0}
      style={
        history.location.pathname !== "/"
          ? { backgroundColor: "#fff" }
          : { backgroundColor: "#fdb52d" }
      }
      position="relative"
      elevation={0}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
      {account && (
        <Dialog
          open={account && parseInt(chainId) !== parseInt(ACTIVE_NETWORK)}
          minWidth="md"
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Netwok Change Error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please switch to {NetworkContextName}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Container maxWidth="lg">
        <Grid className="dflex">
          <Logo
            width="102"
            onClick={() => {
              history.push("/");
            }}
          />
          <Box flexGrow={1} />
          <Hidden smDown>
            {sections[0].items.map((item, index) => {
              return (
                <Link className={classes.navButton} to={item.href}>
                  {" "}
                  <Button className={classes.toolbar} key={`nav${index}`}>
                    {item.title}
                  </Button>
                </Link>
              );
            })}
             <a className={classes.navButton} target="_blank" href="https://memeverse-launchpad.gitbook.io/product-docs/   ">
                  {" "}
                  <Button className={classes.toolbar} >
                   Docs
                  </Button>
                </a>
          </Hidden>
          <Hidden mdUp>
            <SwipeableTemporaryDrawer sections={sections} />
          </Hidden>
        </Grid>
      </Container>
    </>
  );
}
