import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Grid,
} from '@material-ui/core';
import DialogTitleWithCloseIcon from './DialogTitleWithCloseIcon';
import { SUPPORTED_WALLETS } from 'src/connectors';
import { useWeb3React } from '@web3-react/core';
import React, { useState } from 'react';

export default function ConnectWallet({ open, close }) {
  const { activate, account, deactivate } = useWeb3React();
  const [error, setError] = useState('');

  const connectWallet = (connector) => {
    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        console.log('ERROR', error);
        setError(error.message);
        activate(connector);
      }
    });
  };

  return (
    <Dialog open={open} onClose={close} maxWidth='sm' fullWidth>
      <DialogTitleWithCloseIcon title='Select a wallet' onClose={close} />
      <DialogContent>
        <Box mb={4}>
          <Grid container spacing={2}>
            {SUPPORTED_WALLETS.map((item) => {
              return (
                <Grid item xs={6} sm={4} key={item.name}>
                  <Box
                    display='flex'
                    justifyContent='flex-start'
                    alignItems='center'
                    onClick={() => connectWallet(item.data.connector)}
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: 5,
                      padding: 10,
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      src={`images/${item.data.iconName}`}
                      alt=''
                      width='24'
                      style={{ marginRight: 10 }}
                    />
                    <Typography>{item.data.name}</Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {error !== '' && (
            <Box mt={2}>
              <Typography color='error'>{error}</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
