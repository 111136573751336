/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  List,
  ListSubheader,
  makeStyles,
  Avatar,Link,
  Typography, Button,
  IconButton,
} from "@material-ui/core";
import Logo from "src/component/Logo";

import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { Menu } from "react-feather";

import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "#fabc12",
  },
  avatar: {
    cursor: "pointer",
    width: 50,
    height: 50,
  },
  avatarBig: {
    cursor: "pointer",
    width: 150,
    height: 150,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
}));

const NavBar = ({ sections }) => {
  const classes = useStyles();
  const [rightBar, setRightBar] = useState(false);
  const location = useLocation();
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2} pb={0}>
          <Logo width="132" />
         
        </Box>
        <Box pt={2}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
           
        </Box>
        <Box pl={2} style={{paddingLeft:"19px", marginTop:"-5px"}}>
          <Link target="_blank" component={Button}
        variant="text"
       style={{color:"#000", }}
    href="https://memeverse-launchpad.gitbook.io/product-docs/"
        startIcon={<HiOutlineDocumentDuplicate style={{width:"25px"}}/>}
      >
        Docs
      </Link>
        </Box>
      {/* <a  target="_blank" href="https://memeverse-launchpad.gitbook.io/product-docs/   ">
                  {" "}
                  <Button >
                   Docs
                  </Button>
                </a>  */}
               
      </PerfectScrollbar>
      {/* <Box>
        <ListItem button>
          <ListItemIcon>
            <HiOutlineDocumentDuplicate />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
        </ListItem>
        </Box> */}
    </Box>
  );

  return (
    <>
      <IconButton
        onClick={() => {
          setRightBar(!rightBar);
        }}
      >
        <Menu color="#ffffff" style={{ width: 40, height: 40 }} />
      </IconButton>

      <Drawer
        anchor="right"
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
        {/* <Box>
        <ListItem button>
          <ListItemIcon>
            <HiOutlineDocumentDuplicate />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
        </ListItem>
        </Box>  */}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
