import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    // Mui: {
    //   selected: { borderRadius: "50px" },
    // },
    PrivateTabIndicator: {
      colorSecondary: {
        height: "0",
      },
    },
    MuiTableCell: {
      root: {
        padding: "8px 16px",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
      colorSecondary: {
        height: "17px",
        backgroundColor: "#F1F1F1",
        borderRadius: "17px",
      },
      barColorSecondary: {
        backgroundColor: "#FABC12",
        borderRadius: "30px",
      },
      colorPrimary: {
        height: "17px",
        backgroundColor: "#F1F1F1",
        borderRadius: "30px",
      },
      barColorPrimary: {
        background: "linear-gradient(90deg, #0240de 50%, #33B7E0 100%)",
        borderRadius: "30px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiListItemText: {
      multiline: {
        fontWeight: "bold",
        fontSize: "36px",
        lineHeight: "44px",
        color: "#000000",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 50,
        color: "#fff",
        height: "48px",
        paddingLeft: "30px",
      },
      notchedOutline: {
        border: "2px solid #fff",
        borderColor: "#fff",
        paddingLeft: "30px",
      },
    },
    MuiTab: {
      wrapper: {
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "25px",
        padding: "0 20px",
        textAlign: "center",
        color: "#000000",
        fontFamily: "'Montserrat', sans-serif",
        "@media(max-width:991px)": {
          fontSize: "14px",
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        border: "1.5px solid #FABC12",
        borderRadius: "50px",
        backgroundColor: "#fff",
        maxWidth: "fit-content",
        margin: "0 auto",
        // width: "700px",
        minWidth: "650px",
      },
      //       {
      //         background: "#FABC12",
      // borderRadius: "50px",
      //       }
    },

    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 6px) scale(1)",
        fontSize: "14px",
        paddingLeft: "30px",
        lineHeight: "37px",
        color: "#fff",
        fontWeight: "600",
        "&.MuiInputLabel-shrink": {
          transform: "translate(19px, -9px) scale(0.55)",
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: "#fff",
      },
      colorSecondary: {
        color: "#222",
      },
    },
    MuiButton: {
      root: {
        color: "#fff",
      },
      contained: {
        boxShadow: "none",
        borderRadius: 5,
        "&:hover": {
          boxShadow: "none",
        },
      },
      outlined: {
        boxShadow: "none",
        borderRadius: 27,
        borderWidth: 2,
      },
      outlinedPrimary: {
        borderRadius: 27,
        boxShadow: "none",
      },
      containedSizeLarge: {
        color: "#ffffff",
        padding: "14px 50px",
      },
      outlinedSecondary: {
        borderRadius: "50px",
        backgroundColor: "#fff",
        border: "2px solid #FABC12",
        padding: "4px 25px 4px 25px ",
        height: "40px",
        fontSize: "14px",
        maxWidth: "160px",
        whiteSpace: "nowrap",
        lineHeight: "37px",
        color: "#FABC12",
        fontWeight: "600",
        '&.Mui-disabled':{
          color:"#000",
        },
        "@media (max-width: 767px)": {
          fontSize: "15px",
          lineHeight: "24px",
        },
        "&:hover": {
          backgroundColor: "#f5f5f5",
          border: "2px solid #FABC12",
        },
      },
      containedSizeSmall: {
        borderRadius: "50px",
        backgroundColor: "#fff",
        padding: "4px 25px 4px 25px ",
        height: "40px",
        fontSize: "14px",
        maxWidth: "160px",
        whiteSpace: "nowrap",
        lineHeight: "37px",
        color: "#FABC12",
        fontWeight: "600",
        "@media (max-width: 767px)": {
          fontSize: "15px",
          lineHeight: "24px",
        },
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
      },
      containedSecondary: {
        borderRadius: "50px",
        backgroundColor: "#fff",
        padding: "4px 20px 4px 14px ",
        height: "50px",
        fontSize: "20px",
        lineHeight: "37px",
        color: "#FF8316",
        fontWeight: "600",
        "@media (max-width: 767px)": {
          fontSize: "17px",
          padding: "4px 25px 4px 8px ",
          height: "50px",
          lineHeight: "20px",
        },
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Montserrat', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#7d58f2",
      dark: "#202831",
      light: "#e2e3fa",
    },
    secondary: {
      main: "#ff0610",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
