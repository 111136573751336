import React, { createContext, useEffect, useState } from 'react'
import { injected } from 'src/connectors'
import { useWeb3React } from '@web3-react/core'
import Web3 from 'web3'
import FactoryAbi from 'src/context/Abi/FactoryAbi.json'
import InfoAbi from 'src/context/Abi/InfoAbi.json'
import {
  FactoryAddress,
  ApproveTokenAddress,
  defaultpresaleAddress,
} from 'src/context/index'
import TestIDOPresaleABI from 'src/context/Abi/TestIDOPresaleABI.json'
import apiConfig from 'src/assets/ApiConfig'
import axios from 'axios'
import { RPC_URL } from './Utils'
export const UserContext = createContext()

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem('userAddress', userAddress)
  } else {
    sessionStorage.removeItem('userAddress')
  }
}

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem('userToken', token)
  } else {
    sessionStorage.removeItem('userToken')
  }
}

export let NewId = ''

const poolListingData = async ({ setList, setNumberOfPools }) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  const web3 = new Web3(httpProvider)

  try {
    const contract = new web3.eth.Contract(FactoryAbi, FactoryAddress)

    const poolDataListing = await contract.methods.TESTIDO().call()

    const poolListing = new web3.eth.Contract(InfoAbi, poolDataListing)

    const getpresell = await poolListing.methods.getPresalesCount().call()
    setNumberOfPools(getpresell)
    var add = []
    for (var i = getpresell - 1; i >= 0; i--) {
      var Id = await poolListing.methods.getPresaleAddress(i).call()
      NewId = Id
      // console.log('id===>>>', Id)
      const AbiList = new web3.eth.Contract(TestIDOPresaleABI, Id)
      // console.log('Abi', AbiList)
      const dataPool2 = await AbiList.methods.closeTime().call()
      const totalInvestorsCount = await AbiList.methods
        .totalInvestorsCount()
        .call()
      const ammLiquidityAdded = await AbiList.methods.ammLiquidityAdded().call()
      const ammLiquidityPercentageAllocation = await AbiList.methods
        .ammLiquidityPercentageAllocation()
        .call()

      const dataPool = await AbiList.methods.saleTitle().call()

      const dataPool1 = await AbiList.methods.openTime().call()

      const dataPool3 = await AbiList.methods.hardCapInWei().call()

      const dataPool4 = await AbiList.methods.softCapInWei().call()

      const dataPool5 = await AbiList.methods.totalTokens().call()

      const dataPool6 = await AbiList.methods.totalCollectedWei().call()

      const dataPool7 = await AbiList.methods.tokensLeft().call()
      const dataPool8 = await AbiList.methods.presaleCancelled().call()
      const bannerURL = await AbiList.methods.bannerURL().call()
      const tokenPriceInWei = await AbiList.methods.tokenPriceInWei().call()
      const token = await AbiList.methods.token().call()
      const poolId = await AbiList.methods.testIDOId().call()
      // const poolId = await AbiList.methods.getPresalesCount().call()
      // console.log('poolId=======>>>>>', poolId)
      // const poolId = getpresell

      let obj = {
        contractAddress: Id,
        presaleAddress: Id,
        uniLiquidityAdded: JSON.parse(ammLiquidityAdded),
        uniLiquidityPercentageAllocation: ammLiquidityPercentageAllocation.toString(),
        saleTitle: web3.utils.hexToAscii(dataPool),
        openTime: dataPool1,
        closeTime: dataPool2,
        hardCapInWei: web3.utils.fromWei(dataPool3, 'ether'),
        softCapInWei: web3.utils.fromWei(dataPool4, 'ether'),
        totalTokens: web3.utils.fromWei(dataPool5, 'ether'),
        totalCollectedWei: web3.utils.fromWei(dataPool6, 'ether'),
        tokensLeft: web3.utils.fromWei(dataPool7, 'ether'),
        saleLive: dataPool5 - dataPool7,
        presaleCancelled: dataPool8,
        bannerURL: bannerURL,
        tokenPriceInWei: web3.utils.fromWei(tokenPriceInWei, 'ether'),
        token: token,
        poolId: poolId,
      }
      add.push(obj)
      setList((prev) => [...prev, obj])
    }

    // setList(add);
    // console.log('add', add);
  } catch (error) {
    console.log(error)
  }
}

const defaultCardListingData = async ({ setDefaultList }) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  const web3 = new Web3(httpProvider)
  // console.log('defaultpresaleAddress', defaultpresaleAddress)
  // console.log('TestIDOPresaleABI', TestIDOPresaleABI)

  try {
    const AbiListDefault = new web3.eth.Contract(
      TestIDOPresaleABI,
      defaultpresaleAddress,
    )

    // console.log('AbiListDefault*****>', AbiListDefault)

    const dataPool2 = await AbiListDefault.methods.closeTime().call()
    const totalInvestorsCount = await AbiListDefault.methods
      .totalInvestorsCount()
      .call()
    const ammLiquidityAdded = await AbiListDefault.methods
      .ammLiquidityAdded()
      .call()
    const ammLiquidityPercentageAllocation = await AbiListDefault.methods
      .ammLiquidityPercentageAllocation()
      .call()

    const dataPool = await AbiListDefault.methods.saleTitle().call()

    const dataPool1 = await AbiListDefault.methods.openTime().call()

    const dataPool3 = await AbiListDefault.methods.hardCapInWei().call()

    const dataPool4 = await AbiListDefault.methods.softCapInWei().call()

    const dataPool5 = await AbiListDefault.methods.totalTokens().call()

    const dataPool6 = await AbiListDefault.methods.totalCollectedWei().call()

    const dataPool7 = await AbiListDefault.methods.tokensLeft().call()
    const dataPool8 = await AbiListDefault.methods.presaleCancelled().call()
    const adminCheck = await AbiListDefault.methods.testIDODevAddress().call()

    let obj = {
      contractAddress: defaultpresaleAddress,
      presaleAddress: defaultpresaleAddress,
      uniLiquidityAdded: JSON.parse(ammLiquidityAdded),
      uniLiquidityPercentageAllocation: ammLiquidityPercentageAllocation.toString(),
      saleTitle: web3.utils.hexToAscii(dataPool),
      openTime: dataPool1,
      closeTime: dataPool2,
      hardCapInWei: web3.utils.fromWei(dataPool3, 'ether'),
      softCapInWei: web3.utils.fromWei(dataPool4, 'ether'),
      totalTokens: web3.utils.fromWei(dataPool5, 'ether'),
      totalCollectedWei: web3.utils.fromWei(dataPool6, 'ether'),
      tokensLeft: web3.utils.fromWei(dataPool7, 'ether'),
      saleLive: dataPool5 - dataPool7,
      presaleCancelled: dataPool8,
      adminChecks: adminCheck,
    }
    setDefaultList(obj)
    console.log('obj', obj)
  } catch (err) {
    console.log(err)
  }
}

// export NewId=NewId
export default function AuthProvider(props) {
  const { activate, account, deactivate } = useWeb3React()
  const [list, setList] = useState([])
  const [defaultList, setDefaultList] = useState()
  const [pastPollsList, setPastPollsList] = useState([])
  const [upcommingPoolList, setUpcommingPoolList] = useState([])
  const [numberOfPools, setNumberOfPools] = useState(0)
  const [ownerCheck, setOwnerCheck] = useState('')
  const [formdata, setformdata] = useState({
    name: '',
    lastName: '',
  })
  const [isLogin, setIsLogin] = useState(false)
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  const web3 = new Web3(httpProvider)
  useEffect(() => {
    if (list && list.length > 0) {
      let result = list

      let currentTimeStamp = +new Date()
      let filterResult = result.filter((data) => {
        return (
          (data.openTime * 1000 < currentTimeStamp &&
            data.closeTime * 1000 > currentTimeStamp) ||
          data.openTime * 1000 > currentTimeStamp
        )
      })
      setUpcommingPoolList(filterResult)
    }
  }, [list]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (list && list.length > 0) {
      let result = list

      let currentTimeStamp = +new Date()
      let filterResult = result.filter((data) => {
        return data.closeTime * 1000 < currentTimeStamp
      })
      setPastPollsList(filterResult)
    }
  }, [list]) // eslint-disable-line react-hooks/exhaustive-deps

  const disconnectWallte = async () => {
    setIsLogin(false)
    deactivate()
  }
  const ownerFun = async () => {
    try {
      const contract = new web3.eth.Contract(FactoryAbi, FactoryAddress)
      const poolDataListing = await contract.methods.TESTIDO().call()
      const poolListing = new web3.eth.Contract(InfoAbi, poolDataListing)
      const factoryOwner = await poolListing.methods.owner().call()
      setOwnerCheck(factoryOwner)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    // if (account) {
    ownerFun()
    // }
  }, [])

  let data = {
    list,
    defaultList,
    numberOfPools,
    upcommingPoolList,
    pastPollsList,
    isLogin,
    ownerCheck,
    updateUser: () => {
      setSession(account)
    },
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected)
        }
      })
    },
    setformdata: (data) => setformdata(data),
    formdata,
    disconnectToWallet: () => disconnectWallte(),
    poolLists: () => {
      poolListingData({ setList, setNumberOfPools })
    },
    defaultPoolList: () => {
      defaultCardListingData({ setDefaultList })
    },
  }

  // useEffect(() => {
  //   const userAddress = window.sessionStorage.getItem("userAddress");
  //   if (userAddress) {
  //     data.connectWallet();
  //   }
  // }, []); //eslint-disable-line

  useEffect(() => {
    if (account) {
      connectWalletHandler(account)
    }
  }, [account])

  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(apiConfig.connectWallet, {
        walletAddress,
      })
      if (res.data.response_code === 200) {
        setTokenSession(res.data.result.token)
        setIsLogin(true)
      } else {
        setIsLogin(false)
        deactivate()
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  useEffect(() => {
    data.updateUser(account)
    // data.poolLists();
  }, [account]) //eslint-disable-line

  useEffect(() => {
    data.poolLists()
  }, []) //eslint-disable-line
  useEffect(() => {
    data.defaultPoolList()
  }, [])
  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  )
}
