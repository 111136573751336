import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Web3 from 'web3'
import { useWeb3React } from '@web3-react/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import {
  AppBar,
  Box,
  IconButton,
  Typography,
  Toolbar,
  Avatar,
  makeStyles,
  Button,
  Hidden,
  Container,
  Grid,
  DialogTitle,
} from '@material-ui/core'
import SwipeableTemporaryDrawer from './RightDrawer'
import { PieChart as PieChartIcon, Info as InfoIcon } from 'react-feather'
import Logo from 'src/component/Logo'
import { UserContext } from 'src/context/User'
// import { useWeb3React } from "@web3-react/core";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { ACTIVE_NETWORK, NetworkContextName } from 'src/constants'
import { sortAddress } from 'src/constants'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fdb52d',
    borderBottom: '2px solid #D1D0D0',
  },
  toolbar: {
    margin: '10px 0 0',
    paddingBottom: '10px',

    borderBottom: '2px solid #D1D0D0',
    '& a': {
      '& button': {
        borderBottom: '0',
        fontSize: '18px',
        lineHeight: '18px',
        fontWeight: '600',
        fontFamily: "'Montserrat', sans-serif",
        color: '#102A49',
      },
    },
  },
  buttonImg: {
    width: 40,
    height: 40,
    backgroundColor: '#0099FF',
    '& img': {
      width: '30px',
      height: '31px',
    },
  },
  buySwap: {
    borderRadius: '50px',
    backgroundColor: '#fff',
    padding: '4px 15px 4px 14px ',
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.05)',
    height: '50px',
    fontSize: '17px',
    lineHeight: '27px',
    color: '#0099FF',
    fontWeight: '600',
    '@media (max-width: 767px)': {
      fontSize: '15px',
      lineHeight: '25px',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  // login: {
  //   border: "4px solid #FABC12",
  // },
  connectWallet: {
    borderRadius: '50px',
    border: '1px solid #EBEBEB',
    backgroundColor: '#fff',
    padding: '4px 15px 4px 14px ',
    height: '50px',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#464255',
    fontWeight: '600',
    margin: '0 15px',
    fontFamily: "'Montserrat', sans-serif",
    '@media (max-width: 767px)': {
      fontSize: '14px',
      lineHeight: '19px',
    },
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  navButton: {
    marginLeft: '35px',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  ButtonNav: {
    fontSize: 24,
    lineHeight: 9,
    fontWeight: 700,
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  connectWalletss: {
    border: '1px solid #ebe5e5',
    display: 'inline-flex',
    background: '#fff',
    marginLeft: '10px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: ' fit-content',
    borderRadius: '4px',
    '& h4': {
      fontSize: '13px',
      fontWeight: '600',
      color: '#484545',
    },
  },
  connectWallets2: {
    background: '#ececec',
    padding: '13px 7px',
  },
  connectWallets1: {
    padding: '13px 7px',
    cursor: 'pointer',
  },
}))

const sectionsAdmin = [
  {
    items: [
      {
        title: 'Dashboard',
        // icon: PieChartIcon,
        href: '/dashboard',
      },
      {
        title: 'Stake & Airdrop',
        // icon: PieChartIcon,
        href: '/stake',
      },
      {
        title: 'Create',
        href: '/create',
      },
    ],
  },
]

const sections = [
  {
    items: [
      {
        title: 'Dashboard',
        // icon: PieChartIcon,
        href: '/dashboard',
      },
      {
        title: 'Stake & Airdrop',
        // icon: PieChartIcon,
        href: '/stake',
      },
      // {
      //   title: "Create",
      //   href: "/create",
      // },
    ],
  },
]

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <AppBar
      className={history.location.pathname !== '/' ? 'InnerHeader' : 'Header'}
      elevation={0}
      style={
        history.location.pathname !== '/'
          ? { backgroundColor: '#fff' }
          : { backgroundColor: '#fdb52d' }
      }
      position="relative"
      elevation={0}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
}
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
}

export default TopBar

export function TopBarData() {
  const classes = useStyles()
  const history = useHistory()
  const [addressAmount, setAddressAmount] = useState('')
  const web3 = (window.web3 = new Web3(window.ethereum))
  const [admin, setAdmin] = useState('')
  var address = window.sessionStorage.getItem('userAddress')
  const auth = useContext(UserContext)

  const { chainId, account } = useWeb3React()
  var address = window.sessionStorage.getItem('userAddress')
  const [connect, setConnect] = useState(address ? true : false)

  const dataDisplauhandler = async () => {
    try {
      const addressUser = web3.eth.getBalance(account)

      const convertedStringAmount = (await addressUser).toString()

      setAddressAmount(web3.utils.fromWei(convertedStringAmount))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (account) {
      dataDisplauhandler()
    }
  }, [account])

  useEffect(() => {
    const forAdmin = auth?.ownerCheck
    if (forAdmin) {
      setAdmin(forAdmin)
    }
  }, [auth?.ownerCheck, account])
  return (
    <>
      <Container maxWidth="lg">
        <Grid className="flexmenu">
          <Logo
            width="102"
            onClick={() => {
              history.push('/')
            }}
            style={{ cursor: 'pointer' }}
          />
          <Box className="DASHBOARDMENU">
            <Box>
              <Hidden smDown>
                {address === admin ? (
                  <>
                    {sectionsAdmin[0].items.map((item, index) => {
                      return (
                        <Link className={classes.navButton} to={item.href}>
                          {' '}
                          <Button
                            className={classes.toolbar}
                            key={`nav${index}`}
                          >
                            {item.title}
                          </Button>
                        </Link>
                      )
                    })}
                  </>
                ) : (
                  <>
                    {sections[0].items.map((item, index) => {
                      return (
                        <Link className={classes.navButton} to={item.href}>
                          {' '}
                          <Button
                            className={classes.toolbar}
                            key={`nav${index}`}
                          >
                            {item.title}
                          </Button>
                        </Link>
                      )
                    })}
                  </>
                )}
              </Hidden>
            </Box>
            <Box>
              <Hidden smDown>
                {/* <Button
                  className={classes.buySwap}
                  startIcon={
                    <Avatar
                      className={classes.buttonImg}
                      src={'images/pancake.svg'}
                    />
                  }
                >
                  Buy on PancakeSwap
                </Button> */}

                <Box className={classes.connectWalletss}>
                  <Box
                    aria-label="delete"
                    size="small"
                    className={classes.connectWallets2}
                  >
                    <Typography variant="h4">
                      {' '}
                      {addressAmount
                        ? Number(addressAmount).toFixed(3) + ' BNB'
                        : '0.BNB'}
                    </Typography>
                  </Box>
                  <Box
                    aria-label="delete"
                    size="small"
                    className={classes.connectWallets2}
                  >
                    <Typography variant="h4">
                      {' '}
                      {account ? sortAddress(account) : <Box>&nbsp;</Box>}
                    </Typography>
                  </Box>
                  {account ? (
                    <Button
                      className={classes.connectWallets1}
                      onClick={() => {
                        {
                          auth.disconnectToWallet()
                          setAddressAmount([])
                        }
                        // setConnect(false)
                      }}
                    >
                      <Typography variant="h4">
                        {/* {account && sortAddress(account)} */}
                        Disconnect Wallet
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      className={classes.connectWallets1}
                      onClick={() => {
                        auth.connectWallet()
                        // setConnect(true)
                      }}
                      // style={{ cursor: 'pointer' }}
                    >
                      <Typography variant="h4"> Connect Wallet</Typography>
                    </Button>
                  )}
                </Box>
              </Hidden>
            </Box>
          </Box>
          <Hidden mdUp>
            <SwipeableTemporaryDrawer sections={sections} />
          </Hidden>
        </Grid>
      </Container>
      {account && (
        <Dialog
          open={account && parseInt(chainId) !== parseInt(ACTIVE_NETWORK)}
          minWidth="md"
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Netwok Change Error'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please switch to {NetworkContextName}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
