import TokenAbiData from './Abi/TokenAbi.json'
import Abi from './Abi/Abi.json'
export const TokenAbi = TokenAbiData
export const AbiDataETH = Abi
export const factoryContractAddressETH =
  '0xA7b6E6652fD6cD62c427De012659dad4e54B4009'

export const FactoryContractAddressBNB =
  '0xA7b6E6652fD6cD62c427De012659dad4e54B4009'

export const FactoryContractAddressMatic =
  '0xA7b6E6652fD6cD62c427De012659dad4e54B4009'

// export const FactoryAddress = "0x39E2E98Fac3AE65722e7e0a5A32f72E6C742dD91";
export const MultiSendAddress = '0x0fa64cAeb6521a0007FAB6A3223f5a6722016f68'
export const FactoryAddress = '0x2e327c91Eb0EE1f00B4B45da7D29Cbb3024636b4'

// export const FactoryAddress = "0xD8b49EDBa1C5d2b6C09ec543e20C907E51079A95";
export const ApproveTokenAddress = '0xD8dB558eA8E0Cee0275440e9f06DF5Bd350C0045'
export const defaultpresaleAddress =
  '0x15752b252F4Da06FF6E08e27C371995b08263e95'
export function isUrlValid(userInput) {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g, // eslint-disable-line no-useless-escape
  )
  if (res == null) return false
  else return true
}

export function isUrlValidTelegram(userInput) {
  var res = userInput.match(
    /https?:\/\/(t(elegram)?\.me|telegram\.org)\/([a-z0-9\_]{5,32})\/?/g, // eslint-disable-line no-useless-escape
  )
  if (res == null) return false
  else return true
}
