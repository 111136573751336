import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Dashboard/Index")),
  },
  {
    exact: true,
    path: "/stake",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Stake/Index")),
  },
  {
    exact: true,
    path: "/stake-amount",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Stake/StakeAmount")),
  },
  {
    exact: true,
    path: "/projects",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Projects/Index")),
  },
  {
    exact: true,
    path: "/projects/build",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Projects/DefaultIndex")),
  },
  {
    exact: true,
    path: "/create",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/CreatePresale/index")),
  },

  // {
  //   exact: true,
  //   path: "/create",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Home/Creat/CreatePull")),
  // },
  // {
  //   exact: true,
  //   path: "/dashboard",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard")),
  // },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
