let baseurl = 'https://backend.memeverse.fund/'

const apiConfig = {
  connectWallet: `${baseurl}/api/v1/admin/connectWallet`,
  stakeListing: `${baseurl}/api/v1/admin/listStackingAddress`,
  addStakingAddress: `${baseurl}/api/v1/admin/addStackingAddress`,
  addImage: `${baseurl}/api/v1/admin/addImage`,
  addMember: `${baseurl}/api/v1/admin/addMember`,
  listMember: `${baseurl}/api/v1/admin/listMember`,
}

export default apiConfig
