import IERC20ABI from "./ABI/IERC20.json";
import TestIDOFactoryABI from "./ABI/TestIDOFactory.json";
import TestIDOInfoABI from "./ABI/TestIDOInfo.json";
import TestIDOPresaleABI from "./ABI/TestIDOPresale.json";
import TestStakingABI from "./ABI/TestStaking.json";

export const NetworkContextName = "binance test network";
export const ACTIVE_NETWORK = 97;
export const YianmosFactory = "0xD0036D4eB34cf5872a0fdE010Ba5315D32069Cb7";
// export const stakingAddress = '0x636Fed202b40D81ed2e3e19C6e54c01DA6f61159';
export const stakingAddress = "0x1EFb822c95cfA78bD0e2aa7b66B439e5de48890";
export const stakingAddressone = "0x636Fed202b40D81ed2e3e19C6e54c01DA6f61159";

export const IERC20 = IERC20ABI;
export const TestIDOFactory = TestIDOFactoryABI;
export const TestIDOInfo = TestIDOInfoABI;
export const TestIDOPresale = TestIDOPresaleABI;
export const TestStaking = TestStakingABI;

export function isUrlValid(userInput) {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g // eslint-disable-line no-useless-escape
  );
  if (res == null) return false;
  else return true;
}

export function isUrlValidTelegram(userInput) {
  var res = userInput.match(
    /https?:\/\/(t(elegram)?\.me|telegram\.org)\/([a-z0-9\_]{5,32})\/?/g // eslint-disable-line no-useless-escape
  );
  if (res == null) return false;
  else return true;
}

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}
export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};
