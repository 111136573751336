import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('creatturAccessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('creatturAccessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// function checkLogin() {
//   const accessToken = window.localStorage.getItem('creatturAccessToken');
//   return accessToken ? true : false;
// }

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate } = useWeb3React();

  const [isLogin, setIsLogin] = useState(false);
  const [userData] = useState({});
  const [network, setNetwork] = useState();

  useEffect(() => {
    if (account && typeof account !== 'undefined') {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [account]);

  useEffect(() => {
    setNetwork('Ethereum');
  }, [chainId]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    network,
    account,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
